import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Section from 'gatsby-theme-mate/src/components/Section';
import { CardContainer } from 'gatsby-theme-mate/src/components/Card';
import Triangle from 'gatsby-theme-mate/src/components/Triangle';
import { SECTION } from 'gatsby-theme-mate/src/utils/constants';
import { useNessyQuery } from '../../utils/use-nessy-query';
import { useMediumQuery } from '../../utils/use-medium-query';
import { useBlogQuery } from '../../utils/use-blog-query';
import { combinePosts } from '../../utils/combine-posts';
import PostLink from '../../components/post-link';

const Writing = () => {
  const { posts: mediumPosts } = useMediumQuery();
  const nessyPosts = useNessyQuery();
  const blogPosts = useBlogQuery();
  const posts = combinePosts(mediumPosts, nessyPosts, blogPosts).slice(0, 4);

  return (
    <Section.Container id={SECTION.writing} Background={Background}>
      <Section.Header name={SECTION.writing} icon="✍️" label="writing" />
      <CardContainer minWidth="300px">
        <Fade direction="down" triggerOnce cascade damping={0.5}>
          {posts.map((p) => (
            <PostLink {...p} key={p.url} />
          ))}
        </Fade>
      </CardContainer>
    </Section.Container>
  );
};

const Background = () => (
  <>
    <Triangle
      color="secondary"
      height={['15vh', '10vh']}
      width={['100vw', '100vw']}
      position="top-left"
    />

    <Triangle
      color="secondary"
      height={['50vh', '40vh']}
      width={['70vw', '40vw']}
      position="bottom-left"
    />

    <Triangle
      color="primary"
      height={['40vh', '15vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export default Writing;
