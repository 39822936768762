// @ts-ignore
import React from 'react';
import { Heading, Flex, Box } from 'rebass/styled-components';
import Section from 'gatsby-theme-mate/src/components/Section';
import ScrollIcon from 'gatsby-theme-mate/src/components/ScrollIcon';
import Triangle from 'gatsby-theme-mate/src/components/Triangle';
import { useSiteQuery } from 'gatsby-theme-mate/src/queries/useSiteQuery';
import { SECTION } from 'gatsby-theme-mate/src/utils/constants';
import { getSectionHref } from 'gatsby-theme-mate/src/utils/helpers';
import loadable from '@loadable/component';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto', maxWidth: '500px' };
const SocialLink = loadable(() => import('gatsby-theme-mate/src/components/SocialLink'), {
  fallback: null,
});

const LandingPage = () => {
  const { socialLinks } = useSiteQuery();

  return (
    <Section.Container id={SECTION.home} Background={Background}>
      <Heading
        textAlign="center"
        as="h1"
        color="primary"
        fontSize={[4, 5, 6]}
        mb={[3, 4, 5]}
      >
        EnfermeraProgramadora.cl
      </Heading>

      <Heading
        as="h2"
        color="primary"
        fontSize={[3, 4, 5]}
        mb={[3, 5]}
        textAlign="center"
        style={centerHorizontally}
      >
        Compartiendo mis aprendizajes sobre salud, enfermería y tecnología
        &nbsp;
        <span role="img" aria-label="Jeringa">
          💉
        </span>
        &nbsp;
        <span role="img" aria-label="Corazón">
          ❤️
        </span>
        &nbsp;
        <span role="img" aria-label="Programadora">
          👩‍💻
        </span>
      </Heading>

      <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
        {socialLinks.map((sl) => (
          <Box mx={3} fontSize={[5, 6, 6]} key={sl.name}>
            <SocialLink {...sl} />
          </Box>
        ))}
      </Flex>

      <ScrollIcon href={`#${getSectionHref(SECTION.about)}`} />
    </Section.Container>
  );
};

const Background = () => (
  <>
    <Triangle
      color="muted"
      height={['35vh', '67vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="secondary"
      height={['38vh', '67vh']}
      width={['50vw', '35vw']}
    />

    <Triangle
      color="primary"
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      position="top-right"
    />

    <Triangle
      color="muted"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export default LandingPage;
