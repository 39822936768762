import { graphql, useStaticQuery } from 'gatsby';

export const useBlogQuery = () => {
  const { allBlogPost } = useStaticQuery(graphql`
    query BlogQuery {
      allBlogPost(sort: {fields: date, order: DESC}) {
        nodes {
          id
          excerpt
          slug
          title
          date(formatString: "DD [de]  MMMM, YYYY", locale: "es")
          createdAt: date
          image {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
          imageAlt
          imageCaptionText
          imageCaptionLink
          socialImage {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
          tags
        }
      }
    }
  `);

  return allBlogPost.nodes;
};
